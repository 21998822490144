/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/naming-convention */

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface addNewMissionModalState {
  isModalVisible: boolean;
}

const initialState: addNewMissionModalState = {
  isModalVisible: false,
};

export const addNewMissionModalSlice = createSlice({
  name: 'addNewMissionModal',
  initialState,
  reducers: {
    toggleIsAddNewMissionModalVisible: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isModalVisible = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggleIsAddNewMissionModalVisible } =
  addNewMissionModalSlice.actions;

export default addNewMissionModalSlice.reducer;
