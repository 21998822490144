/* eslint-disable import/prefer-default-export */
export enum UserRole {
  ROBOT = 'Robot',
  WINGMAN = 'Wingman',
  PILOT = 'Pilot',
  CAPTAIN = 'Captain',
  ADMIN = 'Admin',
  SUPERUSER = 'SuperUser',
}

export enum RobotCommand {
  STOP = 'stop',
  EXECTUE_ELZ = 'execute_elz',
  SLOW_LAND = 'slow_land',
  FAST_LAND = 'fast_land',
  LEFT_NUDGE = 'left_nudge',
  RIGHT_NUDGE = 'right_nudge',
  FOWARDS_NUDGE = 'forwards_nudge',
  BACKWARDS_NUDGE = 'backwards_nudge',
  UP_NUDGE = 'up_nudge',
  DOWN_NUDGE = 'down_nudge',
  ALL_OFF = 'all_off',
  NUDGE_YAW_RIGHT = 'yaw_right_nudge',
  NUDGE_YAW_LEFT = 'yaw_left_nudge',
}

export enum Day {
  SUNDAY = 'Sunday',
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNESDAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Friday',
  SATURDAY = 'Saturday',
}

export enum RaidObjectType {
  CAR = 'car',
  PEDESTRIAN = 'pedestrian',
}
