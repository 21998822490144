/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/button-has-type */
import { useEffect, useState, useRef } from 'react';
import { FallbackProps } from 'react-error-boundary';

function ErrorFallback({ error }: FallbackProps) {
  const [countdown, setCountdown] = useState(5);
  const [showReload, setShowReload] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const handleGoHomeClick = () => {
    window.location.href = '/';
  };
  const handleContactSupportClick = () => {
    // @ts-ignore:next-line
    window.zE(() => {
      // @ts-ignore:next-line
      zE.activate();
    });
  };

  const handleRefreshClick = () => {
    window.location.reload();
  };

  const handleStopAutoReloadClick = () => {
    setShowReload(true);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };
  // effects

  useEffect(() => {
    if (
      error?.message?.includes('parentNode') ||
      error?.message?.includes('_leaflet_events')
    ) {
      window.location.reload();
    }
  }, [error]);

  useEffect(() => {
    if (!showReload) {
      intervalRef.current = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(intervalRef.current!);
            return 0;
          }
          return prevCountdown - 1;
        });
      }, 1000);

      timeoutRef.current = setTimeout(() => {
        window.location.reload();
      }, 5000);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [showReload]);

  return (
    <div className="h-screen px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <div className="mx-auto max-w-max">
        <main className="sm:flex">
          <p className="text-4xl font-bold tracking-tight text-yellow-400 sm:text-5xl">
            4xx
          </p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-4xl font-bold tracking-tight text-gray-900 dark:text-gray-50 sm:text-5xl">
                Oops! Something happened :(
              </h1>
              <p className="mt-1 text-base text-gray-500">
                {/* Please check the URL in the address bar and try again. */}
                Error message: {error.message}
              </p>
            </div>
            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <div
                className="inline-flex cursor-pointer items-center border border-transparent bg-yellow-400 px-4 py-2 text-sm font-medium text-black shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
                onClick={handleGoHomeClick}
              >
                Go back home
              </div>
              <div
                className="inline-flex cursor-pointer items-center border border-transparent bg-yellow-100 px-4 py-2 text-sm font-medium text-black hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
                onClick={handleContactSupportClick}
              >
                Contact support
              </div>

              {!showReload ? (
                <div
                  className="float-right inline-flex animate-pulse cursor-pointer items-center border border-transparent bg-yellow-400 px-4 py-2 text-xl font-medium text-black shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
                  onClick={handleStopAutoReloadClick}
                >
                  <div className="bold pr-4 text-red-600">{countdown}</div>{' '}
                  Click to Stop Auto Reload
                </div>
              ) : (
                <div
                  className="inline-flex cursor-pointer items-center  border border-transparent bg-yellow-400 px-4 py-2 text-xl font-medium text-black shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
                  onClick={handleRefreshClick}
                >
                  Reload
                </div>
              )}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default ErrorFallback;
