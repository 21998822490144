/* eslint-disable import/prefer-default-export */
import { useQuery, useMutation } from '@tanstack/react-query';
import {
  listActiveDrones,
  getDrones,
  getBases,
  postOpenBase,
  postCloseBase,
  postResetBase,
  putSetDroneNickname,
  postGroundDrone,
  postDroneStreamStart,
  postDroneStreamStop,
  getDroneStatusQL,
  postDroneHoverCommand,
  postDroneResumeCommand,
  postDroneNextWaypointCommand,
  postDroneELZCommand,
  postDroneRTBCommand,
  postDronePatternCommand,
  postDroneApproachCommand,
  postDroneCommand,
  postDroneOrbitCommand,
  listDronesByLocationId,
  postDroneTiltCommand,
  postDroneHeadingCommand,
  listBasesByLocation,
  postDroneActivate,
  postDroneReturnLaunch,
  postTrackObject,
  getEmergencyControlDrones,
  postBaseConnectedToDrone,
} from '../services/droneService';
import { doesUserTokenExist, DefaultDroneProjections } from '../utils';
import {
  IPostDroneHeadingCommandRequest,
  IPostDroneTiltCommandRequest,
} from '../types';

export const useListActiveDrones = (options) =>
  useQuery(['activeDrones'], () => listActiveDrones(), {
    ...options,
    enabled: doesUserTokenExist(),
  });

export const useDronesBylocationID = (locationId, options = {}) =>
  useQuery(
    ['dronesByLocation', locationId],
    () => listDronesByLocationId(locationId),
    {
      ...options,
      enabled: doesUserTokenExist() && locationId !== null,
    }
  );

export const useGetDrones = (options) =>
  useQuery(['drones'], () => getDrones(), {
    ...options,
    enabled: doesUserTokenExist(),
  });

export const useGetEmergencyControlDrones = (options) =>
  useQuery(['emergencyControlDrones'], () => getEmergencyControlDrones(), {
    ...options,
    enabled: doesUserTokenExist(),
  });

export const useGetBases = (options) =>
  useQuery(['bases'], () => getBases(), {
    ...options,
    enabled: doesUserTokenExist(),
  });

export const useListBasesByLocation = (locationId, options = {}) =>
  useQuery(
    ['basesByLocation', locationId],
    () => listBasesByLocation(locationId),
    {
      enabled: locationId !== null || locationId !== undefined,
      ...options,
    }
  );

export const usePostOpenBase = (options) =>
  useMutation((payload: any) => postOpenBase(payload), options);

export const usePostCloseBase = (options) =>
  useMutation((payload: any) => postCloseBase(payload), options);

export const usePostResetBase = (options) =>
  useMutation((payload: any) => postResetBase(payload), options);

export const usePostGroundDrone = (options) =>
  useMutation((payload: any) => postGroundDrone(payload), options);

export const usePostDroneStreamStart = (options) =>
  useMutation((payload: any) => postDroneStreamStart(payload), options);

export const usePostDroneStreamStop = (options) =>
  useMutation((payload: any) => postDroneStreamStop(payload), options);

export const usePutSetDroneNickname = (options) =>
  useMutation((payload: any) => putSetDroneNickname(payload), options);

export const useGetDroneStatusQL = (
  droneName,
  projections = DefaultDroneProjections,
  options: any = {}
) =>
  useQuery(
    [droneName, projections],
    () => getDroneStatusQL(droneName, projections),
    {
      ...options,
      enabled:
        options?.enabled && doesUserTokenExist() && droneName?.length > 0,
      refetchInterval: 2000,
    }
  );

export const usePostDroneHoverCommand = (options) =>
  useMutation((payload: any) => postDroneHoverCommand(payload), options);

export const usePostDroneResumeCommand = (options) =>
  useMutation((payload: any) => postDroneResumeCommand(payload), options);

export const usePostDroneNextWaypointCommand = (options) =>
  useMutation((payload: any) => postDroneNextWaypointCommand(payload), options);

export const usePostDroneRTBCommand = (options) =>
  useMutation((payload: any) => postDroneRTBCommand(payload), options);

export const usePostDroneELZCommand = (options) =>
  useMutation((payload: any) => postDroneELZCommand(payload), options);

export const usePostDronePatternCommand = (options) =>
  useMutation((payload: any) => postDronePatternCommand(payload), options);

export const usePostDroneApproachCommand = (options) =>
  useMutation((payload: any) => postDroneApproachCommand(payload), options);

export const usePostDroneOrbitCommand = (options) =>
  useMutation((payload: any) => postDroneOrbitCommand(payload), options);

export const usePostDroneCommand = (options) =>
  useMutation((payload: any) => postDroneCommand(payload), options);

export const usePostDroneTiltCommand = (options) =>
  useMutation(
    (payload: IPostDroneTiltCommandRequest) => postDroneTiltCommand(payload),
    options
  );

export const usePostDroneHeadingCommand = (options) =>
  useMutation(
    (payload: IPostDroneHeadingCommandRequest) =>
      postDroneHeadingCommand(payload),
    options
  );

export const usePostDroneActivate = () =>
  useMutation((payload: any) => postDroneActivate(payload));

export const usePostDroneReturnLaunch = () =>
  useMutation((payload: any) => postDroneReturnLaunch(payload));

export const usePostTrackObject = () =>
  useMutation((payload: any) => postTrackObject(payload));

export const usePostBaseConnectedToDrone = (options) =>
  useMutation((payload: any) => postBaseConnectedToDrone(payload), options);
