/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export enum LeafletMapType {
  roadmap = 'roadmap',
  satellite = 'satellite',
  hybrid = 'hybrid',
  terrain = 'terrain',
  osm = 'osm',
}

interface ILeafletMapState {
  currentLayer: LeafletMapType;
}

const initialState: ILeafletMapState = {
  currentLayer: LeafletMapType.hybrid,
};

export const leafletMapSlice = createSlice({
  name: 'leafletMap',
  initialState,
  reducers: {
    setCurrentLayer: (state, action: PayloadAction<LeafletMapType>) => {
      state.currentLayer = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCurrentLayer } = leafletMapSlice.actions;

export default leafletMapSlice.reducer;
