/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface EmergencyControlsState {
  isKillPowerModalVisible: boolean;
  isVisible: boolean;
  currentECdrone: any;
}

const initialState: EmergencyControlsState = {
  isKillPowerModalVisible: false,
  isVisible: false,
  currentECdrone: null,
};

export const emergencyControlsStateSlice = createSlice({
  name: 'emergencyControlsState',
  initialState,
  reducers: {
    toggleIsEmergencyControlsStateVisible: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isVisible = action.payload;
    },
    toggleIsKillPowerModalVisible: (state, action: PayloadAction<boolean>) => {
      state.isKillPowerModalVisible = action.payload;
    },
    setCurrentECdrone: (state, action: PayloadAction<any>) => {
      state.currentECdrone = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleIsEmergencyControlsStateVisible,
  toggleIsKillPowerModalVisible,
  setCurrentECdrone,
} = emergencyControlsStateSlice.actions;

export default emergencyControlsStateSlice.reducer;
