/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface IRaidZoneModalsState {
  isCreateNewRuleModalVisible: boolean;
  isRaidZoneRuleSelectModalVisible: boolean;
  isDeleteRaidZoneRuleModalVisible: boolean;
  ruleObject: any;
  isEditing: boolean;
}

const initialState: IRaidZoneModalsState = {
  isCreateNewRuleModalVisible: false,
  isRaidZoneRuleSelectModalVisible: false,
  isDeleteRaidZoneRuleModalVisible: false,
  ruleObject: null,
  isEditing: false,
};

export const raidZoneModalsSlice = createSlice({
  name: 'raidZoneModals',
  initialState,
  reducers: {
    toggleIsCreateNewRuleModalVisible: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isCreateNewRuleModalVisible = action.payload;
    },
    toggleIsRaidZoneRuleSelectModalVisible: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isRaidZoneRuleSelectModalVisible = action.payload;
    },
    toggleIsDeleteRaidZoneRuleModalVisible: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isDeleteRaidZoneRuleModalVisible = action.payload;
    },
    setRuleObject: (state, action: PayloadAction<any>) => {
      state.ruleObject = action.payload;
    },
    setIsEditing: (state, action: PayloadAction<boolean>) => {
      state.isEditing = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleIsCreateNewRuleModalVisible,
  toggleIsRaidZoneRuleSelectModalVisible,
  toggleIsDeleteRaidZoneRuleModalVisible,
  setRuleObject,
  setIsEditing,
} = raidZoneModalsSlice.actions;

export default raidZoneModalsSlice.reducer;
