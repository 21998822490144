/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IRequestMissionModalState {
  isModalVisible: boolean;
  requestedMission: IRequestedMission | null;
  isConfirmModalVisible: boolean;
  confirmRequestedMission: any | null;
}

interface IRequestedMission {
  name: string;
  flight_paths: any[];
  message: string;
  queue_id: string;
  snotify_parameters: any;
  topic: string;
  user_id: string;
  drone: any;
}

const initialState: IRequestMissionModalState = {
  isModalVisible: false,
  requestedMission: null,
  isConfirmModalVisible: false,
  confirmRequestedMission: null,
};

export const requestMissionModalSlice = createSlice({
  name: 'requestMissionFeedbackModal',
  initialState,
  reducers: {
    toggleIsRequestMissionModalVisible: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isModalVisible = action.payload;
    },
    setRequestMissionModalMission: (
      state,
      action: PayloadAction<IRequestedMission | null>
    ) => {
      state.requestedMission = action.payload;
    },
    toggleIsConfirmRequestMissionModalVisible: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isConfirmModalVisible = action.payload;
    },
    setConfirmRequestMissionModalMission: (
      state,
      action: PayloadAction<IRequestedMission | null>
    ) => {
      state.confirmRequestedMission = action.payload;
    },
  },
});
export const {
  toggleIsRequestMissionModalVisible,
  setRequestMissionModalMission,
  toggleIsConfirmRequestMissionModalVisible,
  setConfirmRequestMissionModalMission,
} = requestMissionModalSlice.actions;

export default requestMissionModalSlice.reducer;
