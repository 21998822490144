/* eslint-disable import/prefer-default-export */
import L from 'leaflet';
import type { Feature, Point, FeatureCollection } from 'geojson';
import { SocketDrawingOption, UnitSystemOption } from './constants';
import FlagIcon from '../assets/flag-icon.svg';
import MobileIcon from '../assets/mobile.svg';
// import RadarIcon from '../assets/radar.svg';
// import CommunistIcon from '../assets/communist.svg';
import DefaultIcon from '../assets/defaultIcon.svg';
import PlaneIcon from '../assets/redPlaneIcon.svg';
import PlaneIconOutlined from '../assets/redOutlinePlane.svg';
import { convertMeterToFeet } from './unitSystem';
// import ElzIcon from '../assets/elz-icon.svg';

export const getMarkerForSocketDrawingOption = (
  point: Feature<Point, any>,
  latLng: L.LatLng,
  UserUnitSystem: string
): L.Marker<any> | L.CircleMarker<any> => {
  // force return null if invalid point
  // if (!Object.prototype.hasOwnProperty.call(point?.properties, 'id')) {
  //   return null;
  // }
  const drawingOption: SocketDrawingOption =
    point?.properties?.leafletOptions?.leafletFeatureType || null;
  const defaultIcon = DefaultIcon;

  switch (drawingOption) {
    case SocketDrawingOption.ICON:
      // eslint-disable-next-line no-case-declarations
      let myIcon = defaultIcon;
      // eslint-disable-next-line no-case-declarations
      const animation = '';

      // for mobile status
      if (point?.properties?.mobile_id) {
        myIcon = MobileIcon;
      }

      // checks if this feature is a detection or the device location
      // TODO: need to extract seperate components
      // if (
      //   point?.properties?.radar_id &&
      //   !point?.properties?.radar_id.includes('detection')
      // ) {
      //   myIcon = RadarIcon;
      // } else if (
      //   point?.properties?.radar_id &&
      //   point?.properties?.radar_id.includes('detection')
      // ) {
      //   myIcon = CommunistIcon;
      //   animation = 'animate-pulse';
      // }

      // default icon
      return L.marker(latLng, {
        draggable: false,
        title: point.properties.name,
        icon: L.icon({
          iconUrl: point?.properties?.style?.iconUrl || myIcon,
          iconSize: [40, 40],
          iconAnchor: undefined,
          className: animation,
        }),
      });
    case SocketDrawingOption.DIVICON:
      // iris detections
      if (point.properties.iris_id || point.properties.matrix_id) {
        const headingInDeg = (point.properties.heading * 180) / Math.PI;
        let altitude = point.properties.altitude
          ? `${Math.round(point.properties.altitude)} m MSL`
          : `${Math.round(point.properties.altitude_MSL)} m MSL`;
        if (UserUnitSystem === UnitSystemOption.IMPERIAL) {
          altitude = point.properties.altitude
            ? `${Math.floor(
                Math.round(convertMeterToFeet(point.properties.altitude))
              )} ft MSL`
            : `${Math.floor(
                Math.round(convertMeterToFeet(point.properties.altitude_MSL))
              )} ft MSL`;
        }
        if (point.properties.matrix_id) {
          return L.marker(latLng, {
            draggable: false,
            title: point.properties.name,
            icon: L.divIcon({
              className: point.properties.name,
              iconSize: [70, 70],
              iconAnchor: [35, 15],
              html: `<div style="text-align:center;" className="relative z-40">
                  <img style="margin:0 auto; width:30px; transform: rotate(${headingInDeg}deg)" src="${PlaneIconOutlined}"/>
                  <span style="filter:drop-shadow(2px 4px 6px black);">
                    ${point.properties.name.replace('MatrixSpace ', '')}:
                    <br/>
                    ${point.properties.id}
                    <br/>
                    ${altitude}
                  </span>
                </div>`,
            }),
          });
        }

        return L.marker(latLng, {
          draggable: false,
          title: point.properties.name,
          icon: L.divIcon({
            className: point.properties.name,
            iconSize: [70, 70],
            iconAnchor: [35, 15],
            html: `<div style="text-align:center;" className="relative z-40">
              <img style="margin:0 auto; width:30px; transform: rotate(${headingInDeg}deg)" src="${PlaneIcon}"/>
              <span style="filter:drop-shadow(2px 4px 6px black);">${point.properties.name}: ${altitude}</span>
              </div>`,
          }),
        });
      }

      // default DivIcon
      return L.marker(latLng, {
        draggable: false,
        title: point.properties.name,
        icon: L.divIcon({
          iconUrl: FlagIcon,
          iconSize: [24, 24],
          iconAnchor: [1, 26],
        }),
      });

    case SocketDrawingOption.CIRCLEMARKER:
      // default circle marker (currently untested)
      return L.circleMarker(latLng, {
        ...point?.properties?.style,
      });
    default:
      // if no drawingOption (leafletFeatureType) is not given
      // console.log(point.properties);

      // if device is from radarfence
      if (point?.properties?.radar_id === 'demo1') {
        return L.circleMarker(latLng, {
          radius: 8,
          fillColor: '#00ff00',
          color: '#f00',
          weight: 1,
          opacity: 1,
          fillOpacity: 0.8,
        })
          .bindTooltip(
            `<div style="width 200px">` +
              // `<p style="margin:0 auto; overflow:scroll">${JSON.stringify(point.properties)}</p>` +
              `<p style="margin:0 auto;"> ID: ${point.properties.id}</p>` +
              `<p style="margin:0 auto; "> Longtitude: ${point.properties.lon}</p>` +
              `<p style="margin:0 auto; "> Latitude: ${point.properties.lat}</p>` +
              `<div>`,
            { permanent: true, opacity: 0.7, offset: [20, 0] }
          )
          .openTooltip();
      }
      // if detection is a radarfence detection
      if (point?.properties?.radar_id === 'demo1_detection') {
        return L.circleMarker(latLng, {
          radius: 8,
          fillColor: '#00ff00',
          color: '#f00',
          weight: 1,
          opacity: 1,
          fillOpacity: 0.8,
        })
          .bindTooltip(
            `<div style="width 200px">` +
              // `<p style="margin:0 auto; overflow:scroll">${JSON.stringify(point.properties)}</p>` +
              `<p style="margin:0 auto;"> ID: ${point.properties.id}</p>` +
              `<p style="margin:0 auto; "> Longtitude: ${point.properties.lon}</p>` +
              `<p style="margin:0 auto; "> Latitude: ${point.properties.lat}</p>` +
              `<p style="margin:0 auto; "> Distance: ${point.properties.dist} m</p>` +
              `<div>`,
            { permanent: true, opacity: 0.7, offset: [20, 0] }
          )
          .openTooltip();
      }
      return L.circleMarker(latLng, {
        radius: 8,
        fillColor: '#00ff00',
        color: '#f00',
        weight: 1,
        opacity: 1,
        fillOpacity: 0.8,
      })
        .bindTooltip(
          `<div style="width 200px">` +
            `<p style="margin:0 auto; overflow:scroll">${JSON.stringify(
              point.properties
            )}</p>` +
            `<div>`,
          { permanent: true, opacity: 0.7, offset: [20, 0] }
        )
        .openTooltip();
  }
};

export const setStyle = (feature) => {
  return {
    fillColor: feature.properties.fillColor,
    color: feature.properties.fillColor,
    // fillOpacity: feature.properties.fillOpacity,
    // opacity: feature.properties.strokeOpacity,
  };
};

export const convertMapToGeoJSONLeafletArray = (
  geoJSONSocketData: Map<string, Map<string, Feature>>
): FeatureCollection[] => {
  // console.log('geoJSONSocketData');
  const geoJSONCollectionArray = <any>[];

  geoJSONSocketData.forEach((collection, index) => {
    const tmpFeatures = <any>[];
    collection.forEach((feature) => {
      tmpFeatures.push(feature);
    });
    geoJSONCollectionArray.push({ type: index, features: tmpFeatures });
  });
  return geoJSONCollectionArray;
};
