/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction, current } from '@reduxjs/toolkit';
import { enableMapSet } from 'immer';
import type { FeatureCollection, Feature } from 'geojson';
import { convertMapToGeoJSONLeafletArray } from '../utils/geoJSONSocket';

enableMapSet();
interface GeoJSONSocketState {
  geoJSONSocketFeatureArray: FeatureCollection[];
  geoJSONSocketMap: Map<string, Map<string, Feature>>;
}

const initialState: GeoJSONSocketState = {
  geoJSONSocketFeatureArray: [],
  geoJSONSocketMap: new Map(),
};

export const socketGeoJSONSlice = createSlice({
  name: 'geoJSONSocket',
  initialState,
  reducers: {
    clearGeoJSONArray: (state) => {
      state.geoJSONSocketFeatureArray = [];
      state.geoJSONSocketMap = new Map();
    },
    addNewGeoJSON: (state, action: PayloadAction<FeatureCollection>) => {
      // if collection level doesnt exist
      if (!current(state).geoJSONSocketMap.has(action.payload.type)) {
        // make new feature level map from payload
        const payloadFeaturesMap = new Map();
        action.payload.features.forEach((feature) => {
          payloadFeaturesMap.set(feature?.properties?.id, feature);
        });
        // create new collection with mapped features
        state.geoJSONSocketMap.set(action.payload.type, payloadFeaturesMap);
      } else {
        // if collection level exists
        const featureMap = state.geoJSONSocketMap.get(action.payload.type);

        action.payload.features.forEach((feature) => {
          const payloadFeatureId = feature?.properties?.id as string;
          const payloadFeatureValue = feature;

          // check timestamp is newer than current
          if (
            (featureMap?.has(payloadFeatureId) &&
              feature?.properties?.timestamp >
                featureMap?.get(payloadFeatureId)?.properties?.timestamp) ||
            !featureMap?.has(payloadFeatureId)
          ) {
            // TODO check timestamp
            featureMap?.set(payloadFeatureId, payloadFeatureValue);
          }
        });
      }

      // remove old timestamp features
      // for (const [collectionKey, collectionValue] of state.geoJSONSocketMap) {
      //   for (const [featureKey, featureValue] of collectionValue) {
      //     // console.log(value2.properties.timestamp);
      //     const featureTimestamp = new Date(
      //       featureValue?.properties?.timestamp
      //     ).getTime();
      //     const currentTimestamp = new Date().getTime();
      //     if (currentTimestamp - featureTimestamp > 10000) {
      //       console.log('old');
      //       state?.geoJSONSocketMap?.get(collectionKey)?.delete(featureKey);
      //     }
      //   }
      // }

      state.geoJSONSocketFeatureArray = convertMapToGeoJSONLeafletArray(
        state.geoJSONSocketMap
      );

      // filter timestamp older than 10 seconds
      const decayTime = 3000; // milliseconds

      current(state).geoJSONSocketFeatureArray.forEach(
        (collection, collectionIndex) => {
          // const radarStatusCollection = 'RadarStatusCollection';
          // eslint-disable-next-line prefer-template
          // const collectionType = '' + collection.type;
          collection.features.forEach((feature, featureIndex) => {
            if (feature?.properties?.expirationTimestamp) {
              const featureTimestamp = new Date(
                feature?.properties?.expirationTimestamp
              ).getTime();
              const currentTimestamp = new Date().getTime();
              if (currentTimestamp - featureTimestamp > decayTime) {
                state?.geoJSONSocketFeatureArray[
                  collectionIndex
                ]?.features.splice(featureIndex, 1);
                state?.geoJSONSocketMap
                  ?.get(collection?.type)
                  ?.delete(feature?.properties?.id);
              }
            }
          });
        }
      );
    },
  },
});

export const { clearGeoJSONArray, addNewGeoJSON } = socketGeoJSONSlice.actions;

export default socketGeoJSONSlice.reducer;
