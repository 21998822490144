/* eslint-disable consistent-return */
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  toggleIsMissionFeedbackModalVisible,
  setMissionFeedbackModalMission,
} from '../store/missionFeedbackModalSlice';
import {
  toggleIsMissionSummaryModalVisible,
  setMissionSummaryModalMission,
} from '../store/missionSummarySlice';
import { toggleIsNotificationModalVisible } from '../store/notificationDropdownSlice';
import {
  toggleIsRequestMissionModalVisible,
  setRequestMissionModalMission,
  toggleIsConfirmRequestMissionModalVisible,
  setConfirmRequestMissionModalMission,
} from '../store/requestMissionSlice';
import { toggleIsVersionModalVisible } from '../store/versionModalSlice';
import { toggleIsWeatherModalVisible } from '../store/weatherModalSlice';
import { getUserIdFromToken } from './localStorage';

/* eslint-disable import/prefer-default-export */
export const useCloseAllUtil = () => {
  // useRef
  const inactivityTimerRef = useRef<NodeJS.Timeout | null>(null);
  // redux
  const dispatch = useDispatch();
  const userId = getUserIdFromToken();

  const handleCloseModals = useCallback(() => {
    // mission feedback modal
    dispatch(toggleIsMissionFeedbackModalVisible(false));
    dispatch(setMissionFeedbackModalMission(null));
    // mission summary modal
    dispatch(toggleIsMissionSummaryModalVisible(false));
    dispatch(setMissionSummaryModalMission(null));
    // notification modal
    dispatch(toggleIsNotificationModalVisible(false));
    // request mission modals
    dispatch(toggleIsRequestMissionModalVisible(false));
    dispatch(setRequestMissionModalMission(null));
    dispatch(toggleIsConfirmRequestMissionModalVisible(false));
    dispatch(setConfirmRequestMissionModalMission(null));
    // version modal
    dispatch(toggleIsVersionModalVisible(false));
    // weather modal
    dispatch(toggleIsWeatherModalVisible(false));
  }, [dispatch]);

  const handleVisibilityChange = useCallback(() => {
    if (document.visibilityState === 'hidden') {
      inactivityTimerRef.current = setTimeout(() => {
        handleCloseModals();
      }, 30000); // 30 seconds
    } else if (inactivityTimerRef.current) {
      clearTimeout(inactivityTimerRef.current);
      inactivityTimerRef.current = null;
    }
  }, [handleCloseModals]);

  useEffect(() => {
    if (!userId) return;
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      if (inactivityTimerRef.current) {
        clearTimeout(inactivityTimerRef.current);
      }
    };
  }, [userId, handleVisibilityChange]);
};
