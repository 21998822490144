/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface NavbarState {
  isVisible: boolean;
}

const initialState: NavbarState = {
  isVisible: true,
};

export const navbarSlice = createSlice({
  name: 'navbar',
  initialState,
  reducers: {
    toggleIsNavBarVisible: (state, action: PayloadAction<boolean>) => {
      state.isVisible = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggleIsNavBarVisible } = navbarSlice.actions;

export default navbarSlice.reducer;
