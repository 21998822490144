/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface DarkModeState {
  isDarkMode: boolean;
}

const toggleDOMTheme = (isDark: boolean) => {
  if (isDark) {
    document.body.classList.add('dark', 'bg-black');
    document.body.classList.remove('bg-gray-100');
  } else {
    document.body.classList.remove('dark', 'bg-black');
    document.body.classList.add('bg-gray-100');
  }
};

const getInitialTheme = (): DarkModeState => {
  // const isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
  // toggleDOMTheme(isDarkMode);
  toggleDOMTheme(true);
  return {
    isDarkMode: true,
  };
};

export const darkModeSlice = createSlice({
  name: 'darkMode',
  initialState: getInitialTheme(),
  reducers: {
    toggleIsDarkMode: (state, action: PayloadAction<boolean>) => {
      state.isDarkMode = action.payload;
      toggleDOMTheme(action.payload);
    },
  },
});

export const { toggleIsDarkMode } = darkModeSlice.actions;

export default darkModeSlice.reducer;
