import { configureStore } from '@reduxjs/toolkit';
import navbarReducer from './navbarSlice';
import darkModeReducer from './darkModeSlice';
import locationPageReducer from './locationPageSlice';
import notificationDropdownReducer from './notificationDropdownSlice';
import weatherModalReducer from './weatherModalSlice';
import deleteMissionModalReducer from './deleteMissionModalSlice';
import livePageReducer from './livePageSlice';
import EmergencyControlsReducer from './emergencyControlsSlice';
import chatModalReducer from './chatModalSlice';
import LeafletMapReducer from './leafletMapSlice';
import soundIconReducer from './soundSlice';
import versionModalReducer from './versionModalSlice';
import missionFeedbackModalReducer from './missionFeedbackModalSlice';
import missionSummaryModalReducer from './missionSummarySlice';
import prelaunchModalsReducer from './prelaunchModalsSlice';
import requestMissionModalReducer from './requestMissionSlice';
import addNewMissionModalReducer from './addNewMissionModalSlice';
import raidZoneModalsReducer from './raidZoneModalsSlice';
import missionReducer from './missionSlice';
import socketGeoJSONModalReducer from './socketGeoJSONSlice';
import missionsPageReducer from './missionsPageSlice';
import twoFAModalReducer from './twoFAModalSlice';
import totalStatsModalReducer from './totalStatsModal';
import fleetPageReducer from './fleetPageSlice';

export const store = configureStore({
  reducer: {
    navbar: navbarReducer,
    darkMode: darkModeReducer,
    locationPage: locationPageReducer,
    notificationDropdown: notificationDropdownReducer,
    weatherModal: weatherModalReducer,
    deleteMissionModal: deleteMissionModalReducer,
    livePage: livePageReducer,
    emergencyControls: EmergencyControlsReducer,
    chatModal: chatModalReducer,
    leafletMap: LeafletMapReducer,
    soundOn: soundIconReducer,
    versionModal: versionModalReducer,
    missionFeedbackModal: missionFeedbackModalReducer,
    missionSummaryModal: missionSummaryModalReducer,
    prelaunchModals: prelaunchModalsReducer,
    requestMissionModal: requestMissionModalReducer,
    addNewMissionModal: addNewMissionModalReducer,
    raidZoneModals: raidZoneModalsReducer,
    mission: missionReducer,
    geoJSONSocket: socketGeoJSONModalReducer,
    missionsPage: missionsPageReducer,
    twoFAModal: twoFAModalReducer,
    totalStatsModal: totalStatsModalReducer,
    fleetPage: fleetPageReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
