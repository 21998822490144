/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface VersionModalState {
  isModalVisible: boolean;
}

const initialState: VersionModalState = {
  isModalVisible: false,
};

export const versionModalSlice = createSlice({
  name: 'versionModal',
  initialState,
  reducers: {
    toggleIsVersionModalVisible: (state, action: PayloadAction<boolean>) => {
      state.isModalVisible = action.payload;
    },
  },
});

export const { toggleIsVersionModalVisible } = versionModalSlice.actions;

export default versionModalSlice.reducer;
