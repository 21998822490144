/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { LocalStorageKeys, getIsSoundOnFromStorage } from '../utils';

interface SoundState {
  isSoundOn: boolean;
}

const getInitialSoundState = (): SoundState => {
  return {
    isSoundOn: getIsSoundOnFromStorage(),
  };
};

export const soundSlice = createSlice({
  name: 'soundOn',
  initialState: getInitialSoundState(),
  reducers: {
    toggleIsSoundOn: (state, action: PayloadAction<boolean>) => {
      state.isSoundOn = action.payload;
      localStorage.setItem(
        LocalStorageKeys.IS_SOUND_ON,
        JSON.stringify(action.payload)
      );
    },
  },
});

export const { toggleIsSoundOn } = soundSlice.actions;

export default soundSlice.reducer;
