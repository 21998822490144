export const GOOGLE_API_KEY = import.meta.env.PROD
  ? 'AIzaSyBHSwRToujmpARzqyCBoBCZELTZW3jMAGI'
  : 'AIzaSyDHu10uIFuaSF_BaqeV2A6V9YBng36F7SY';

/* eslint-disable import/prefer-default-export */
export const formatWaypointsToGoogleStaticImgUrl = (waypoints: any[]) => {
  if (!waypoints?.length) return '';
  let pathStr = '&path=color:0x0d47a1|weight:5|';
  let markerStr = '';
  waypoints.forEach((curWaypoint, index) => {
    pathStr += `${curWaypoint.lat},${curWaypoint.lon}|`;
    if (index === 0) {
      markerStr += `&markers=color:0xF5C53E|label:A|${curWaypoint.lat},${curWaypoint.lon}`;
    } else if (index === waypoints.length - 1) {
      markerStr += `&markers=color:0xF5C53E|label:B|${curWaypoint.lat},${curWaypoint.lon}`;
    } else {
      markerStr += `&markers=color:0xF5C53E|${curWaypoint.lat},${curWaypoint.lon}`;
    }
  });
  pathStr = pathStr.substring(0, pathStr.length - 1);
  return `https://maps.googleapis.com/maps/api/staticmap?maptype=satellite&style=element:geometry%7Ccolor:0x212121&style=element:labels.icon%7Cvisibility:off&style=element:labels.text.fill%7Ccolor:0x757575&style=element:labels.text.stroke%7Ccolor:0x212121&style=feature:administrative%7Celement:geometry%7Ccolor:0x757575&style=feature:administrative.country%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&style=feature:administrative.land_parcel%7Cvisibility:off&style=feature:administrative.locality%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:poi.park%7Celement:geometry%7Ccolor:0x181818&style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:poi.park%7Celement:labels.text.stroke%7Ccolor:0x1b1b1b&style=feature:road%7Celement:geometry.fill%7Ccolor:0x2c2c2c&style=feature:road%7Celement:labels.text.fill%7Ccolor:0x8a8a8a&style=feature:road.arterial%7Celement:geometry%7Ccolor:0x373737&style=feature:road.highway%7Celement:geometry%7Ccolor:0x3c3c3c&style=feature:road.highway.controlled_access%7Celement:geometry%7Ccolor:0x4e4e4e&style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x616161&style=feature:transit%7Celement:labels.text.fill%7Ccolor:0x757575&style=feature:water%7Celement:geometry%7Ccolor:0x000000&style=feature:water%7Celement:labels.text.fill%7Ccolor:0x3d3d3d&size=256x256${markerStr}${pathStr}&key=${GOOGLE_API_KEY}&v=3.exp`;
};
