/* eslint-disable import/no-duplicates */
/* eslint-disable import/prefer-default-export */
import reactHotToast from 'react-hot-toast';
import {
  DefaultToastOptions,
  Renderable,
  Toast,
  ToastOptions,
  ValueOrFunction,
} from 'react-hot-toast';
import { debounce } from 'lodash';

import YellowNotificationSound from '../assets/sounds/yellowNotification.wav';
import RedNotificationSound from '../assets/sounds/rednotification.wav';
import { getIsSoundOnFromStorage } from './localStorage';
import TcasTrafficSound from '../assets/sounds/tcas-traffic.wav';

export enum MessageOptions {
  WARNING = 'WARNING',
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
  TRAFFIC = 'TRAFFIC',
}
type MessageType = MessageOptions | null;
type Message = ValueOrFunction<Renderable, Toast>;
interface IToastOptions extends ToastOptions {
  type?: MessageType;
}

const PlaySound = debounce((sound) => new Audio(sound).play(), 500);
const CheckSoundType = (type: MessageType | undefined): void => {
  switch (type) {
    case MessageOptions.WARNING:
      PlaySound(YellowNotificationSound);
      break;
    case MessageOptions.ERROR:
      PlaySound(RedNotificationSound);
      break;
    case MessageOptions.TRAFFIC:
      PlaySound(TcasTrafficSound);
      break;
    default:
      break;
  }
};

const toast = (message: Message, options?: IToastOptions) => {
  if (getIsSoundOnFromStorage()) CheckSoundType(options?.type);
  return reactHotToast(message, options);
};

toast.success = (message: Message, options?: IToastOptions) => {
  if (getIsSoundOnFromStorage()) CheckSoundType(MessageOptions.ERROR);
  return reactHotToast.success(message, options);
};

toast.error = (message: Message, options?: IToastOptions) => {
  if (getIsSoundOnFromStorage()) CheckSoundType(MessageOptions.ERROR);
  return reactHotToast.error(message, options);
};

toast.loading = (message: Message, options?: IToastOptions) => {
  if (getIsSoundOnFromStorage()) CheckSoundType(options?.type);
  return reactHotToast.loading(message, options);
};

toast.custom = (message: Message, options?: IToastOptions) => {
  if (getIsSoundOnFromStorage()) CheckSoundType(options?.type);
  return reactHotToast.custom(message, options);
};

toast.promise = <T>(
  promise: Promise<T>,
  msgs: {
    loading: Renderable;
    success: ValueOrFunction<Renderable, T>;
    error: ValueOrFunction<Renderable, any>;
  },
  options?: DefaultToastOptions & { type?: MessageType }
) => {
  if (getIsSoundOnFromStorage()) CheckSoundType(options?.type);
  return reactHotToast.promise(promise, msgs, options);
};

toast.dismiss = (toastId?: string) => reactHotToast.dismiss(toastId);

toast.remove = (toastId?: string) => reactHotToast.remove(toastId);

export { toast };
