/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface TotalStatsModal {
  isVisible: boolean;
}
const initialState: TotalStatsModal = {
  isVisible: false,
};

export const totalStatsModalSlice = createSlice({
  name: 'totalStatsModalState',
  initialState,
  reducers: {
    toggleIsTotalStatsModalVisible: (state, action: PayloadAction<boolean>) => {
      state.isVisible = action.payload;
    },
  },
});

export const { toggleIsTotalStatsModalVisible } = totalStatsModalSlice.actions;

export default totalStatsModalSlice.reducer;
