/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface DeleteMissionModalState {
  isDeleteMissionModalVisible: boolean;
  currentSelectedMissionId: null | string;
}

const initialState: DeleteMissionModalState = {
  isDeleteMissionModalVisible: false,
  currentSelectedMissionId: null,
};

export const deleteMissionModalSlice = createSlice({
  name: 'deleteMissionModal',
  initialState,
  reducers: {
    toggleIsDeleteMissionModalVisible: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isDeleteMissionModalVisible = action.payload;
    },
    setCurrentSelectedMissionId: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.currentSelectedMissionId = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleIsDeleteMissionModalVisible,
  setCurrentSelectedMissionId,
} = deleteMissionModalSlice.actions;

export default deleteMissionModalSlice.reducer;
