import axios from 'axios';
import Cookies from 'js-cookie';
import { LocalStorageKeys } from '../utils';

// sea3 -> https://sea3.nightingalesecurity.com:3000
// flight -> https://flight-138.nightingalesecurity.com
const devUrl = 'https://flight-138.nightingalesecurity.com';

export const baseURL = import.meta.env.PROD ? '' : devUrl;

const axiosInstance = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    // 'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use((config) => {
  const loginCheck = Cookies.get('chocolateChip');
  // eslint-disable-next-line no-param-reassign
  config.headers.Authorization = loginCheck ? Cookies.get('chocolateChip') : '';
  return config;
});

axiosInstance.interceptors.response.use(undefined, (error) => {
  if (error?.response?.status === 401) {
    Cookies.remove('chocolateChip');
    localStorage.removeItem(LocalStorageKeys.USER_TOKEN);
    localStorage.removeItem(LocalStorageKeys.IS_SOUND_ON);
    if (window.location.pathname !== '/login') {
      window.location.replace(`/login?redirect=${window.location.pathname}`);
    }
  }
  return Promise.reject(error);
});

export default axiosInstance;
