/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/naming-convention */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface missionSummaryModalState {
  isModalVisible: boolean;
  mission: any;
}

const initialState: missionSummaryModalState = {
  isModalVisible: false,
  mission: null,
};

export const missionSummaryModalSlice = createSlice({
  name: 'missionSummaryModal',
  initialState,
  reducers: {
    toggleIsMissionSummaryModalVisible: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isModalVisible = action.payload;
    },
    setMissionSummaryModalMission: (state, action: PayloadAction<any>) => {
      state.mission = action.payload;
    },
  },
});

export const {
  toggleIsMissionSummaryModalVisible,
  setMissionSummaryModalMission,
} = missionSummaryModalSlice.actions;

export default missionSummaryModalSlice.reducer;
