import { Route } from '@tanstack/react-location';

import { Suspense, lazy } from 'react';

const Archive = lazy(() => import('./Archive'));
const Docs = lazy(() => import('./Docs'));
const Events = lazy(() => import('./Events'));
const Fleet = lazy(() => import('./Fleet'));
const Live = lazy(() => import('./Live'));
const Location = lazy(() => import('./Location'));
const Locations = lazy(() => import('./Locations'));
const Login = lazy(() => import('./Login'));
const Mission = lazy(() => import('./Mission'));
const Missions = lazy(() => import('./Missions'));
const PageNotFound = lazy(() => import('./PageNotFound'));
const PilotStats = lazy(() => import('./PilotStats'));
const RecoverPassword = lazy(() => import('./RecoverPassword'));
const Schedule = lazy(() => import('./Schedule'));
const Settings = lazy(() => import('./Settings'));
const Team = lazy(() => import('./Team'));

const routes: Route[] = [
  {
    path: '/',
    element: (
      <Suspense fallback={null}>
        <Login />
      </Suspense>
    ),
  },
  {
    path: '/login',
    element: (
      <Suspense fallback={null}>
        <Login />
      </Suspense>
    ),
  },
  {
    path: '/missions',
    element: (
      <Suspense fallback={null}>
        <Missions />
      </Suspense>
    ),
  },
  {
    path: '/live/:missionId',
    element: (
      <Suspense fallback={null}>
        <Live />
      </Suspense>
    ),
  },
  {
    path: '/mission/:missionId',
    element: (
      <Suspense fallback={null}>
        <Mission />
      </Suspense>
    ),
  },
  {
    path: '/mission/new',
    element: (
      <Suspense fallback={null}>
        <Mission />
      </Suspense>
    ),
  },
  {
    path: '/locations',
    element: (
      <Suspense fallback={null}>
        <Locations />
      </Suspense>
    ),
  },
  {
    path: '/location/:locationId',
    element: (
      <Suspense fallback={null}>
        {' '}
        <Location />
      </Suspense>
    ),
  },
  {
    path: '/recover-password',
    element: (
      <Suspense fallback={null}>
        <RecoverPassword />
      </Suspense>
    ),
  },
  {
    path: '/schedule',
    element: (
      <Suspense fallback={null}>
        <Schedule />
      </Suspense>
    ),
  },
  {
    path: '/fleet',
    element: (
      <Suspense fallback={null}>
        <Fleet />
      </Suspense>
    ),
  },
  {
    path: '/archive',
    element: (
      <Suspense fallback={null}>
        <Archive />
      </Suspense>
    ),
  },
  {
    path: '/events',
    element: (
      <Suspense fallback={null}>
        <Events />
      </Suspense>
    ),
  },
  {
    path: '/docs',
    element: (
      <Suspense fallback={null}>
        <Docs />
      </Suspense>
    ),
  },
  {
    path: '/team',
    element: (
      <Suspense fallback={null}>
        <Team />
      </Suspense>
    ),
  },
  {
    path: '/settings',
    element: (
      <Suspense fallback={null}>
        <Settings />
      </Suspense>
    ),
  },
  {
    path: '/pilot-stats',
    element: (
      <Suspense fallback={null}>
        <PilotStats />
      </Suspense>
    ),
  },
  {
    element: (
      <Suspense fallback={null}>
        <PageNotFound />
      </Suspense>
    ),
  },
];

export default routes;
