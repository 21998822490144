/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DrawingOption } from '../utils';
import { Day, IMissionSchedule } from '../types';

interface IMissionState {
  isLeftNavExpanded: boolean;
  locationId: string | null;
  missionName: string | null;
  currentSelectedFeatureId: null | string;
  currentSelectedDrawOption: null | DrawingOption;
  schedule: IMissionSchedule[];
  isScheduleEditorOpen: boolean;
  isSetAllEditorOpen: boolean;
  isSaveAsNewMissionModalOpen: boolean;
  showWaypointHeadings: boolean;
  isClearAllWaypointsWarningModalOpen: boolean;
  isDirty: boolean;
}

const initialState: IMissionState = {
  isLeftNavExpanded: true,
  locationId: null,
  missionName: null,
  currentSelectedFeatureId: null,
  currentSelectedDrawOption: null,
  schedule: [
    { name: Day.SUNDAY, times: [] },
    { name: Day.MONDAY, times: [] },
    { name: Day.TUESDAY, times: [] },
    { name: Day.WEDNESDAY, times: [] },
    { name: Day.THURSDAY, times: [] },
    { name: Day.FRIDAY, times: [] },
    { name: Day.SATURDAY, times: [] },
  ],
  isScheduleEditorOpen: false,
  isSetAllEditorOpen: false,
  isSaveAsNewMissionModalOpen: false,
  showWaypointHeadings: true,
  isClearAllWaypointsWarningModalOpen: false,
  isDirty: false,
};

export const missionSlice = createSlice({
  name: 'mission',
  initialState,
  reducers: {
    toggleIsLeftNavExpanded: (state, action: PayloadAction<boolean>) => {
      state.isLeftNavExpanded = action.payload;
    },
    setLocationId: (state, action: PayloadAction<string | null>) => {
      state.locationId = action.payload;
    },
    setMissionName: (state, action: PayloadAction<string | null>) => {
      state.missionName = action.payload;
    },
    setCurrentSelectedFeatureId: (
      state,
      action: PayloadAction<string | null>
    ) => {
      state.currentSelectedFeatureId = action.payload;
    },
    setCurrentSelectedDrawOption: (
      state,
      action: PayloadAction<DrawingOption | null>
    ) => {
      state.currentSelectedDrawOption = action.payload;
    },
    setSchedule: (state, action: PayloadAction<IMissionSchedule[]>) => {
      state.schedule = action.payload;
    },
    setIsScheduleEditorOpen: (state, action: PayloadAction<boolean>) => {
      state.isScheduleEditorOpen = action.payload;
    },
    setIsAllEditorOpen: (state, action: PayloadAction<boolean>) => {
      state.isSetAllEditorOpen = action.payload;
    },
    setIsSaveAsNewMissionModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isSaveAsNewMissionModalOpen = action.payload;
    },
    toggleShowWaypointHeadings: (state, action: PayloadAction<boolean>) => {
      state.showWaypointHeadings = action.payload;
    },
    setIsClearAllWaypointsWarningModalOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isClearAllWaypointsWarningModalOpen = action.payload;
    },
    setIsDirty: (state, action: PayloadAction<boolean>) => {
      state.isDirty = action.payload;
    },
  },
});

export const {
  toggleIsLeftNavExpanded,
  setLocationId,
  setMissionName,
  setCurrentSelectedFeatureId,
  setCurrentSelectedDrawOption,
  setSchedule,
  setIsScheduleEditorOpen,
  setIsAllEditorOpen,
  setIsSaveAsNewMissionModalOpen,
  toggleShowWaypointHeadings,
  setIsClearAllWaypointsWarningModalOpen,
  setIsDirty,
} = missionSlice.actions;

export default missionSlice.reducer;
