/* eslint-disable import/prefer-default-export */
import { LocalStorageKeys, getUserToken } from '../utils';
import api from './baseService';

export const listUsersLoginStatus = async () => {
  // const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const { data } = await api.get(`/api/users/getLoginStatus`);
  return data;
};

export const getUserSettings = async () => {
  const token = getUserToken();
  if (token) {
    const { data } = await api.get(`/api/users/settings`);
    return data;
  }
  return null;
};

export const postUserSettings = (settings) => {
  const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const requestBody = {
    ...settings,
    token,
  };
  return api.post('/api/users/settings', requestBody);
};

export const listUsers = async () => {
  // const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const { data } = await api.get(`/api/users/list`);
  return [...data].sort((a, b) => (a.role.level < b.role.level ? 1 : -1));
};

export const postDeleteUser = (email) => {
  const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const requestBody = {
    email,
    token,
  };
  return api.post('/api/users/delete', requestBody);
};

export const postAddUser = (options) => {
  const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const requestBody = {
    ...options,
    token,
  };
  return api.post('/api/users/new', requestBody);
};

export const postUpdateUserPassword = (options) => {
  const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const requestBody = {
    ...options,
    token,
  };
  return api.post('/api/users/changePassword', requestBody);
};

export const postUpdateUser = (options) => {
  const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const requestBody = {
    ...options,
    token,
  };
  return api.post('/api/users/update', requestBody);
};

export const getUserInfo = async (userId: string) => {
  // const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const { data } = await api.get(`/api/users/info?user_id=${userId}`);
  return data;
};
