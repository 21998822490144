import Cookies from 'js-cookie';
import {
  IApiResponse,
  IPostDisable2FA,
  IPostEnable2FA,
  IPostLoginRequest,
  IPostLoginResponse,
  IPostPasswordRecoveryRequest,
  IPostPasswordRecoverySubmitRequest,
  IPostSSOresponse,
} from '../types';
import { LocalStorageKeys } from '../utils';
import api from './baseService';

export const postLogin = (payload: IPostLoginRequest) => {
  const requestBody: IPostLoginRequest = {
    password: payload.password,
    remember_browser: payload.rememberMe,
    two_factor_code: payload.two_factor_code,
    browser_uuid: payload.browser_uuid,
  };
  if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(payload.email)) {
    requestBody.username = payload.email;
  } else {
    requestBody.email = payload.email;
  }
  return api.post<IPostLoginResponse>(`/api/authenticate`, requestBody);
};

export const getLogout = () => {
  // const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  Cookies.remove('chocolateChip');
  localStorage.removeItem(LocalStorageKeys.USER_TOKEN);
  return api.get<IApiResponse>(`/api/oauth/logout`);
};

export const postPasswordRecoveryRequest = (
  payload: IPostPasswordRecoveryRequest
) => {
  const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const requestBody: IPostPasswordRecoveryRequest = {
    token,
    user_email: payload.email,
  };
  return api.post<IApiResponse>(`/api/password-recovery-request`, requestBody);
};

export const postPasswordRecoverySubmit = (
  payload: IPostPasswordRecoverySubmitRequest
) => {
  const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const requestBody: IPostPasswordRecoverySubmitRequest = {
    token,
    new_password: payload.new_password,
    request_sign: payload.request_sign,
  };
  return api.post<IApiResponse>(`/api/password-recovery-submit`, requestBody);
};

export const getPasswordRecoveryCheck = (request_sign: string) => {
  return api.get<IApiResponse>(
    `/api/password-recovery-check?request_sign=${request_sign}`
  );
};

export const postTwoFA = (payload: IPostEnable2FA) => {
  const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const requestBody: IPostEnable2FA = {
    token,
    two_factor_code: payload.two_factor_code,
    two_factor_secret: payload.two_factor_secret,
  };
  return api.post(`/api/users/2fa-setup`, requestBody);
};

export const deleteDisableTwoFA = (payload: IPostDisable2FA) => {
  const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const requestBody: IPostDisable2FA = {
    token,
    two_factor_code: payload.two_factor_code,
  };
  return api.delete(`/api/users/2fa-setup`, { data: requestBody });
};

export const getTwoFACode = async () => {
  // const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const { data } = await api.get(`/api/users/2fa-setup`);
  return data;
};

export const getSSOstatus = async () => {
  const { data } = await api.get<IPostSSOresponse>('/api/saml/status');
  return data;
};

export const postCaptchaValidate = (payload) => {
  const requestBody = { ...payload };
  return api.post('/api/validateCaptcha', requestBody);
};

export const postCaptchaServe = (payload) => {
  const requestBody = { ...payload };
  return api.post('/api/serveCaptcha', requestBody);
};
