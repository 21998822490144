/* eslint-disable import/no-cycle */
/* eslint-disable import/prefer-default-export */
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getUserInfo,
  getUserSettings,
  listUsersLoginStatus,
  postUserSettings,
  listUsers,
  postDeleteUser,
  postAddUser,
  postUpdateUserPassword,
  postUpdateUser,
  getLogout,
} from '../services';
import { doesUserTokenExist, getUserIdFromToken } from '../utils';

export const useListUsersLoginStatus = (options) =>
  useQuery(['usersLoginStatus'], () => listUsersLoginStatus(), {
    ...options,
    enabled: doesUserTokenExist(),
  });

export const useGetUserSettings = (options) =>
  useQuery(['userSettings'], () => getUserSettings(), {
    ...options,
    enabled: doesUserTokenExist(),
    onError(err: any) {
      const message = err?.response?.data?.error;
      const status = err?.response?.status;
      if (message === 'Unable to find user' && status === 404) {
        getLogout();
      }
    },
  });

export const usePostUserSettings = (options) =>
  useMutation((payload: any) => postUserSettings(payload), options);

export const useListUsers = (options) =>
  useQuery(['users'], () => listUsers(), {
    ...options,
    enabled: doesUserTokenExist(),
  });

export const usePostDeleteUser = () =>
  useMutation((payload: any) => postDeleteUser(payload));

export const usePostAddUser = (options) =>
  useMutation((payload: any) => postAddUser(payload), options);

export const usePostUpdateUserPassword = (options) =>
  useMutation((payload: any) => postUpdateUserPassword(payload), options);

export const usePostUpdateUser = (options) =>
  useMutation((payload: any) => postUpdateUser(payload), options);

export const useGetUserInfo = (userId: string = getUserIdFromToken()) =>
  useQuery(['user', userId], () => getUserInfo(userId), {
    enabled: doesUserTokenExist() && userId !== '',
  });
