/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface FleetPageState {
  DroneVideModalOpen: boolean;
  StartStreamModalOpen: boolean;
  DroneLocation: any;
  BaseObject: any;
  EditDronePropertiesModalOpen: boolean;
  EditBasePropertiesModalOpen: boolean;
}

const initialState: FleetPageState = {
  DroneVideModalOpen: false,
  StartStreamModalOpen: false,
  DroneLocation: null,
  BaseObject: null,
  EditDronePropertiesModalOpen: false,
  EditBasePropertiesModalOpen: false,
};

export const fleetPageSlice = createSlice({
  name: 'FleetPageState',
  initialState,
  reducers: {
    toggleDroneVideModalOpen: (state, action: PayloadAction<boolean>) => {
      state.DroneVideModalOpen = action.payload;
    },
    toggleStartStreamModalOpen: (state, action: PayloadAction<boolean>) => {
      state.StartStreamModalOpen = action.payload;
    },
    setDroneLocation: (state, action: PayloadAction<any>) => {
      state.DroneLocation = action.payload;
    },
    toggleEditDronePropertiesModalOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.EditDronePropertiesModalOpen = action.payload;
    },
    setBaseObject: (state, action: PayloadAction<any>) => {
      state.BaseObject = action.payload;
    },
    toggleEditBasePropertiesModalOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.EditBasePropertiesModalOpen = action.payload;
    },
  },
});

export const {
  toggleDroneVideModalOpen,
  toggleStartStreamModalOpen,
  setDroneLocation,
  toggleEditDronePropertiesModalOpen,
  setBaseObject,
  toggleEditBasePropertiesModalOpen,
} = fleetPageSlice.actions;

export default fleetPageSlice.reducer;
