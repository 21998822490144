import io from 'socket.io-client';
import { baseURL } from './baseService';

const socket = io(baseURL);

export const handleEmitError = (result) => {
  if (!result.success) {
    console.error(result.error);
  }
};

export default socket;
