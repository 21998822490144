/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface TwoFAModalState {
  isTwoFAModalVisible: boolean;
  isTwoFAEnabled: boolean;
  qrData: string;
  secret: string;
}

const initialState: TwoFAModalState = {
  isTwoFAModalVisible: false,
  isTwoFAEnabled: false,
  qrData: '',
  secret: '',
};

export const twoFAModalSlice = createSlice({
  name: 'twoFAModalState',
  initialState,
  reducers: {
    toggleIsTwoFAModalVisible: (state, action: PayloadAction<boolean>) => {
      state.isTwoFAModalVisible = action.payload;
    },
    toggleIsTwoFAEnabled: (state, action: PayloadAction<boolean>) => {
      state.isTwoFAEnabled = action.payload;
    },
    setQRdata: (state, action: PayloadAction<string>) => {
      state.qrData = action.payload;
    },
    setSecret: (state, action: PayloadAction<string>) => {
      state.secret = action.payload;
    },
  },
});

export const {
  toggleIsTwoFAModalVisible,
  toggleIsTwoFAEnabled,
  setQRdata,
  setSecret,
} = twoFAModalSlice.actions;

export default twoFAModalSlice.reducer;
