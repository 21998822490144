import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getLogout,
  getPasswordRecoveryCheck,
  postLogin,
  postPasswordRecoveryRequest,
  postPasswordRecoverySubmit,
  postTwoFA,
  getTwoFACode,
  deleteDisableTwoFA,
  getSSOstatus,
  postCaptchaValidate,
  postCaptchaServe,
} from '../services';
import {
  IPostDisable2FA,
  IPostEnable2FA,
  IPostLoginRequest,
  IPostPasswordRecoveryRequest,
  IPostPasswordRecoverySubmitRequest,
} from '../types';
import { doesUserTokenExist } from '../utils';

export const useGetLogout = () => useMutation(() => getLogout());

export const usePostLogin = () =>
  useMutation((payload: IPostLoginRequest) => postLogin(payload), {});

export const usePostPasswordRecoveryRequest = () =>
  useMutation(
    (payload: IPostPasswordRecoveryRequest) =>
      postPasswordRecoveryRequest(payload),
    {}
  );

export const usePostPasswordRecoverySubmit = () =>
  useMutation(
    (payload: IPostPasswordRecoverySubmitRequest) =>
      postPasswordRecoverySubmit(payload),
    {}
  );

export const useGetPasswordRecoveryCheck = (request_sign: any) =>
  useQuery(
    ['password-recovery-check', request_sign],
    () => getPasswordRecoveryCheck(request_sign),
    {
      enabled: !!request_sign,
      retry: 0,
    }
  );

export const usePostTwoFA = () =>
  useMutation((payload: IPostEnable2FA) => postTwoFA(payload), {});

export const useDeleteDisableTwoFA = () =>
  useMutation((payload: IPostDisable2FA) => deleteDisableTwoFA(payload));

export const useGetTwoFACode = () =>
  useQuery(['twoFACode'], () => getTwoFACode(), {
    enabled: doesUserTokenExist(),
  });

export const useGetSSostatus = () =>
  useQuery(['getSSOstatus'], () => getSSOstatus());

export const usePostCaptchaValidate = () =>
  useMutation((payload: any) => postCaptchaValidate(payload));

export const usePostCaptchaServe = () =>
  useMutation((payload: any) => postCaptchaServe(payload));
