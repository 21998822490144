/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface NotificationDropdownState {
  isModalVisible: boolean;
}

const initialState: NotificationDropdownState = {
  isModalVisible: false,
};

export const notificationDropdownSlice = createSlice({
  name: 'notificationDropdown',
  initialState,
  reducers: {
    toggleIsNotificationModalVisible: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isModalVisible = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggleIsNotificationModalVisible } =
  notificationDropdownSlice.actions;

export default notificationDropdownSlice.reducer;
