/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { LatLngLiteral } from 'leaflet';
import { DroneCommands, LivePageWidgetType, VideoStreamOption } from '../utils';

export interface IDroneOrbitPoint extends LatLngLiteral {
  alt: number;
  radius: number;
}

interface LivePageState {
  isLeftNavExpanded: boolean;
  isTopRightWidgetVisible: boolean;
  isTopRightWidgetExpanded: boolean;
  topRightWidgetType: LivePageWidgetType;
  videoStreamOption: VideoStreamOption;
  showWaypointHeadings: boolean;
  isDroneCommandConfirmationModalOpen: boolean;
  currentDroneCommand: DroneCommands | null;
  isFlyToModalOpen: boolean;
  droneOrbitPoint: IDroneOrbitPoint | null;
  droneFlyToPoint: LatLngLiteral | null;
  droneGazePoint: LatLngLiteral | null;
  droneCommandPoint: LatLngLiteral | null;
  isNotesWidgetOpen: boolean;
  currentRGBTimestamp: number | unknown;
  currentThermalTimestamp: number | unknown;
  socketDroneOrbitPoint: IDroneOrbitPoint | null;
  showGamePadControls: boolean;
  notesTimeStamp: number;
  currentZoomLevel: number;
  currentThermalZoomLevel: number;
  futureZoomLevel: number;
  futureThermalZoomLevel: number;
  isInPiPMode: boolean;
  isInPiPModeThermal: boolean;
  livePagePrevDroneName: any;
  enableFullScreen: boolean;
  enableFullScreenThermal: boolean;
  geoFenceCoordinates: any;
}

const initialState: LivePageState = {
  isLeftNavExpanded: true,
  isTopRightWidgetVisible: true,
  isTopRightWidgetExpanded: false,
  topRightWidgetType: LivePageWidgetType.VIDEO,
  videoStreamOption: VideoStreamOption.RGB,
  showWaypointHeadings: false,
  isDroneCommandConfirmationModalOpen: false,
  currentDroneCommand: null,
  isFlyToModalOpen: false,
  droneOrbitPoint: null,
  droneFlyToPoint: null,
  droneGazePoint: null,
  droneCommandPoint: null,
  isNotesWidgetOpen: false,
  currentRGBTimestamp: null,
  currentThermalTimestamp: null,
  socketDroneOrbitPoint: null,
  showGamePadControls: false,
  notesTimeStamp: 0,
  currentZoomLevel: 1,
  currentThermalZoomLevel: 1,
  futureZoomLevel: 1,
  futureThermalZoomLevel: 1,
  isInPiPMode: false,
  isInPiPModeThermal: false,
  livePagePrevDroneName: '',
  enableFullScreen: false,
  enableFullScreenThermal: false,
  geoFenceCoordinates: null,
};

export const livePageSlice = createSlice({
  name: 'livePage',
  initialState,
  reducers: {
    toggleIsLeftNavExpanded: (state, action: PayloadAction<boolean>) => {
      state.isLeftNavExpanded = action.payload;
    },
    toggleIsTopRightWidgetVisible: (state, action: PayloadAction<boolean>) => {
      state.isTopRightWidgetVisible = action.payload;
    },
    toggleIsTopRightWidgetExpanded: (state, action: PayloadAction<boolean>) => {
      state.isTopRightWidgetExpanded = action.payload;
    },
    setWidgetType: (state, action: PayloadAction<LivePageWidgetType>) => {
      state.topRightWidgetType = action.payload;
    },
    setVideoStreamOption: (state, action: PayloadAction<VideoStreamOption>) => {
      state.videoStreamOption = action.payload;
    },
    toggleShowWaypointHeadings: (state, action: PayloadAction<boolean>) => {
      state.showWaypointHeadings = action.payload;
    },
    toggleIsDroneCommandConfirmationModalOpen: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isDroneCommandConfirmationModalOpen = action.payload;
    },
    setCurrentDroneCommand: (
      state,
      action: PayloadAction<DroneCommands | null>
    ) => {
      state.currentDroneCommand = action.payload;
    },
    setDroneCommandPoint: (
      state,
      action: PayloadAction<LatLngLiteral | null>
    ) => {
      state.droneCommandPoint = action.payload;
    },
    toggleIsFlyToModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isFlyToModalOpen = action.payload;
    },
    setDroneOrbitPoint: (
      state,
      action: PayloadAction<IDroneOrbitPoint | null>
    ) => {
      state.droneOrbitPoint = action.payload;
    },
    setDroneFlyToPoint: (
      state,
      action: PayloadAction<LatLngLiteral | null>
    ) => {
      state.droneFlyToPoint = action.payload || null;
    },
    setDroneGazePoint: (state, action: PayloadAction<LatLngLiteral | null>) => {
      state.droneGazePoint = action.payload;
    },
    toggleIsNotesWidgetOpen: (state, action: PayloadAction<boolean>) => {
      state.isNotesWidgetOpen = action.payload;
    },
    setCurrentRGBTimestamp: (
      state,
      action: PayloadAction<number | unknown>
    ) => {
      state.currentRGBTimestamp = action.payload;
    },
    setCurrentThermalTimestamp: (
      state,
      action: PayloadAction<number | unknown>
    ) => {
      state.currentThermalTimestamp = action.payload;
    },
    setSocketDroneOrbitPoint: (
      state,
      action: PayloadAction<IDroneOrbitPoint | null>
    ) => {
      state.socketDroneOrbitPoint = action.payload;
    },
    toggleShowGamePadControls: (state, action: PayloadAction<boolean>) => {
      state.showGamePadControls = action.payload;
    },
    setNotesTimeStamp: (state, action: PayloadAction<number | 0>) => {
      state.notesTimeStamp = action.payload;
    },
    setCurrentZoomLevel: (state, action: PayloadAction<number | 1>) => {
      state.currentZoomLevel = action.payload;
    },
    setCurrentThermalZoomLevel: (state, action: PayloadAction<number | 1>) => {
      state.currentThermalZoomLevel = action.payload;
    },
    setFutureZoomLevel: (state, action: PayloadAction<number | 1>) => {
      state.futureZoomLevel = action.payload;
    },
    setFutureThermalZoomLevel: (state, action: PayloadAction<number | 1>) => {
      state.futureThermalZoomLevel = action.payload;
    },
    setIsInPiPMode: (state, action: PayloadAction<boolean>) => {
      state.isInPiPMode = action.payload;
    },
    setIsInPiPModeThermal: (state, action: PayloadAction<boolean>) => {
      state.isInPiPModeThermal = action.payload;
    },
    setLivePagePrevDroneName: (state, action: PayloadAction<string>) => {
      state.livePagePrevDroneName = action.payload;
    },
    setEnableFullScreen: (state, action: PayloadAction<boolean>) => {
      state.enableFullScreen = action.payload;
    },
    setEnableFullScreenThermal: (state, action: PayloadAction<boolean>) => {
      state.enableFullScreenThermal = action.payload;
    },
    setGeoFenceCoordinates: (state, action: PayloadAction<any>) => {
      state.geoFenceCoordinates = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  toggleIsLeftNavExpanded,
  toggleIsTopRightWidgetVisible,
  toggleIsTopRightWidgetExpanded,
  setWidgetType,
  setVideoStreamOption,
  toggleShowWaypointHeadings,
  toggleIsDroneCommandConfirmationModalOpen,
  setCurrentDroneCommand,
  toggleIsFlyToModalOpen,
  setDroneOrbitPoint,
  setDroneFlyToPoint,
  setDroneGazePoint,
  setDroneCommandPoint,
  toggleIsNotesWidgetOpen,
  setCurrentRGBTimestamp,
  setCurrentThermalTimestamp,
  setSocketDroneOrbitPoint,
  toggleShowGamePadControls,
  setNotesTimeStamp,
  setCurrentZoomLevel,
  setCurrentThermalZoomLevel,
  setFutureZoomLevel,
  setFutureThermalZoomLevel,
  setIsInPiPMode,
  setIsInPiPModeThermal,
  setLivePagePrevDroneName,
  setEnableFullScreen,
  setEnableFullScreenThermal,
  setGeoFenceCoordinates,
} = livePageSlice.actions;

export default livePageSlice.reducer;
