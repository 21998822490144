import { round } from 'lodash';

import { UnitSystemOption } from './constants';

export const getSmallDistanceUnitShorthand = (
  unitSystem: string | UnitSystemOption
) => {
  if (unitSystem.toUpperCase() === UnitSystemOption.METRIC) {
    return 'm';
  }
  return 'ft';
};

export const convertMeterToFeet = (meter: number): number => {
  return round(meter * 3.28084, 2);
};

export const convertFeetToMeter = (feet: number): number => {
  return round(feet / 3.28084, 2);
};

export const convertFahrenheittoCelsius = (Fahrenheit: number): number => {
  return Math.floor((5 / 9) * (Fahrenheit - 32));
};

export const convertCelsiustoFahrenheit = (Celsius: number): number => {
  return Math.floor(Celsius * (9 / 5) + 32);
};

export const convertMPHoMS = (MPH: number): number => {
  return MPH / 2.23694;
};

export const convertKPHoMS = (MS: number): number => {
  return MS / 3.6;
};

export const convertMSoMPH = (MPH: number): number => {
  return round(MPH * 2.23694, 1);
};

export const convertMSoKPH = (MS: number): number => {
  return round(MS * 3.6, 1);
};

export const convertMPHoKPH = (MPH: number): number => {
  return round(MPH * 1.60934, 1);
};

export const convertKPHoMPH = (KPH: number): number => {
  return round(KPH / 1.60934, 1);
};

export const getSpeedUnitShorthand = (
  unitSystem: string | UnitSystemOption
) => {
  if (!unitSystem) {
    return '';
  }
  if (unitSystem.toUpperCase() === UnitSystemOption.METRIC) {
    return 'kph';
  }
  return 'mph';
};

export const getTempUnitShorthand = (unitSystem: string | UnitSystemOption) => {
  if (!unitSystem) {
    return ' \u00b0';
  }
  if (unitSystem.toUpperCase() === UnitSystemOption.METRIC) {
    return ' \u00b0C';
  }
  return ' \u00b0F';
};
