/* eslint-disable no-param-reassign */
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface MissionFeedbackModalState {
  isModalVisible: boolean;
  mission: any;
}

const initialState: MissionFeedbackModalState = {
  isModalVisible: false,
  mission: null,
};

export const missionFeedbackModalSlice = createSlice({
  name: 'missionFeedbackModal',
  initialState,
  reducers: {
    toggleIsMissionFeedbackModalVisible: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isModalVisible = action.payload;
    },
    setMissionFeedbackModalMission: (state, action: PayloadAction<any>) => {
      state.mission = action.payload;
    },
  },
});

export const {
  toggleIsMissionFeedbackModalVisible,
  setMissionFeedbackModalMission,
} = missionFeedbackModalSlice.actions;

export default missionFeedbackModalSlice.reducer;
