/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IMissionsPageState {
  searchText: string;
  searchLocationId: string | null;
  radioCodeSelection: string[];
}

const initialState: IMissionsPageState = {
  searchText: '',
  searchLocationId: null,
  radioCodeSelection: [],
};

export const missionsPageSlice = createSlice({
  name: 'missionsPage',
  initialState,
  reducers: {
    setSearchText: (state, action: PayloadAction<string>) => {
      state.searchText = action.payload;
    },
    setSearchLocationId: (state, action: PayloadAction<string | null>) => {
      state.searchLocationId = action.payload;
    },
    setRadioCodeSelection: (state, action: PayloadAction<string[]>) => {
      state.radioCodeSelection = action.payload;
    },
  },
});

export const { setSearchText, setSearchLocationId, setRadioCodeSelection } =
  missionsPageSlice.actions;

export default missionsPageSlice.reducer;
