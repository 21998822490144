/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/naming-convention */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface weatherModalState {
  isModalVisible: boolean;
}

const initialState: weatherModalState = {
  isModalVisible: false,
};

export const weatherModalSlice = createSlice({
  name: 'weatherModal',
  initialState,
  reducers: {
    toggleIsWeatherModalVisible: (state, action: PayloadAction<boolean>) => {
      state.isModalVisible = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggleIsWeatherModalVisible } = weatherModalSlice.actions;

export default weatherModalSlice.reducer;
