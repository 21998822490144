/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable import/prefer-default-export */
import {
  IApiResponse,
  IPostDroneHeadingCommandRequest,
  IPostDroneTiltCommandRequest,
} from '../types';
import {
  DefaultDroneProjections,
  LocalStorageKeys,
  getUserToken,
} from '../utils';
import api from './baseService';

export const listActiveDrones = async () => {
  const token = getUserToken();
  if (token) {
    const { data } = await api.get(`/api/drone/findActiveMissions`);
    return data.drones;
  }
  return null;
};

export const getDrones = async () => {
  const token = getUserToken();
  if (token) {
    const { data } = await api.get(`/api/drone/listMM`);
    return data.drones.sort((a, b) => a?.status?.localeCompare(b?.status));
  }
  return null;
};

export const getEmergencyControlDrones = async () => {
  const token = getUserToken();
  if (token) {
    const { data } = await api.get(`/api/drone/listMM`);
    return data.drones.sort((a, b) => a?.status?.localeCompare(b?.status));
  }
  return null;
};

export const listDronesByLocationId = async (locationId) => {
  if (!locationId) {
    return null;
    // throw new Error('Location ID is not provided.');
  }
  try {
    const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
    const requestBody = {
      location_id: locationId,
      token,
    };
    const { data = [] } = await api.post(
      `/api/drone/listByLocationId`,
      requestBody
    );
    data.sort((a, b) => a?.status?.localeCompare(b?.status));
    return data;
  } catch (error) {
    console.error('Error listing drones by location ID:', error);
    return [];
  }
};

export const getBases = async () => {
  // const token = getUserToken();
  const { data } = await api.get(`/api/base/list`);
  return data.bases;
};

export const listBasesByLocation = async (locationId: string) => {
  // const token = getUserToken();
  const { data } = await api.get(
    `/api/base/listByLocation?location_id=${locationId}`
  );
  return data;
};

export const postOpenBase = (name) => {
  const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const requestBody = {
    ...name,
    token,
  };
  return api.post(`/api/base/open`, requestBody);
};

export const postCloseBase = (name) => {
  const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const requestBody = {
    ...name,
    token,
  };
  return api.post(`/api/base/close`, requestBody);
};

export const postResetBase = (name) => {
  const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const requestBody = {
    ...name,
    token,
  };
  return api.post(`/api/base/reset`, requestBody);
};

export const postGroundDrone = (payload) => {
  const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const requestBody = {
    ...payload,
    token,
  };
  return api.post(`/api/drone/ground`, requestBody);
};

export const postDroneStreamStart = (payload) => {
  const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const requestBody = {
    ...payload,
    token,
  };
  return api.post(`/api/drone/camera`, requestBody);
};

export const postDroneStreamStop = (payload) => {
  const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const requestBody = {
    ...payload,
    token,
  };
  return api.post(`/api/drone/stop_stream`, requestBody);
};

export const putSetDroneNickname = (payload) => {
  const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const requestBody = {
    ...payload,
    token,
  };
  return api.put(`/api/drone/${payload._id}/setNickname`, requestBody);
};

export const getDroneStatusQL = async (
  droneName: string,
  projections = DefaultDroneProjections
) => {
  const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const requestBody = {
    token,
    name: droneName,
    projections,
  };
  const response = await api.post(`/api/drone/statusQL`, requestBody);
  const latency =
    Math.abs(
      new Date().getTime() - new Date(response.data.timestamp * 1000).getTime()
    ) / 1000;
  return {
    ...response.data,
    latency,
  };
};

export const postDroneHoverCommand = (payload) => {
  const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const requestBody = {
    ...payload,
    token,
  };
  return api.post(`/api/drone/hover`, requestBody);
};

export const postDroneResumeCommand = (payload) => {
  const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const requestBody = {
    ...payload,
    token,
  };
  return api.post(`/api/drone/resumeRoute`, requestBody);
};

export const postDroneNextWaypointCommand = (payload) => {
  const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const requestBody = {
    ...payload,
    token,
  };
  return api.post(`/api/drone/nextWP`, requestBody);
};

export const postDroneRTBCommand = (payload) => {
  const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const requestBody = {
    ...payload,
    token,
  };
  return api.post(`/api/drone/return`, requestBody);
};

export const postDroneELZCommand = (payload) => {
  const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const requestBody = {
    ...payload,
    token,
  };
  return api.post(`/api/drone/elz`, requestBody);
};

export const postDronePatternCommand = (payload) => {
  const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const requestBody = {
    ...payload,
    token,
  };
  return api.post(`/api/drone/pattern`, requestBody);
};

export const postDroneApproachCommand = (payload) => {
  const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const requestBody = {
    ...payload,
    token,
  };
  return api.post(`/api/drone/approach`, requestBody);
};

export const postDroneOrbitCommand = (payload) => {
  const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const requestBody = {
    ...payload,
    token,
  };
  return api.post(`/api/drone/orbit`, requestBody);
};

export const postDroneCommand = (payload) => {
  const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const requestBody = {
    ...payload,
    token,
  };
  return api.post(`/api/drone/command`, requestBody);
};

export const postDroneTiltCommand = (payload: IPostDroneTiltCommandRequest) => {
  const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const requestBody: IPostDroneTiltCommandRequest = {
    ...payload,
    token,
  };
  return api.post<IApiResponse>(`/api/drone/camera`, requestBody);
};

export const postDroneHeadingCommand = (
  payload: IPostDroneHeadingCommandRequest
) => {
  const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const requestBody: IPostDroneHeadingCommandRequest = {
    ...payload,
    token,
  };
  return api.post<IApiResponse>(`/api/drone/camera`, requestBody);
};

export const postDroneActivate = (payload) => {
  const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const requestBody = {
    ...payload,
    token,
  };
  return api.post(`/api/drone/activate`, requestBody);
};

export const postDroneReturnLaunch = (payload) => {
  const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const requestBody = {
    ...payload,
    token,
  };
  return api.post(`/api/drone/return`, requestBody);
};

export const postTrackObject = (payload) => {
  const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const requestBody = {
    ...payload,
    token,
  };
  return api.post(`/api/drone/trackObject`, requestBody);
};

export const postBaseConnectedToDrone = (payload) => {
  const token = localStorage.getItem(LocalStorageKeys.USER_TOKEN);
  const requestBody = {
    ...payload,
    token,
  };
  return api.post(`/api/drone/getMyBaseStatus`, requestBody);
};
