/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/naming-convention */

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface chatModalState {
  isModalVisible: boolean;
}

const initialState: chatModalState = {
  isModalVisible: false,
};

export const chatModalSlice = createSlice({
  name: 'chatModal',
  initialState,
  reducers: {
    toggleIsChatModalVisible: (state, action: PayloadAction<boolean>) => {
      state.isModalVisible = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggleIsChatModalVisible } = chatModalSlice.actions;

export default chatModalSlice.reducer;
